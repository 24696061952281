// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/common/Navbar';
import Login from './components/auth/Login';
import AdminDashboard from './components/admin/AdminDashboard';
import UserDashboard from './components/user/UserDashboard';
import OrderList from './components/orders/OrderList';
import ProtectedRoute from './components/common/ProtectedRoute';
import UploadCheque from './components/payments/UploadCheque';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>

          <Route
            path="/"
            element={
              <div className="welcome-container">
                <h1>Приветствуем на Крипто Базарe</h1>
                <p>По всем оперативным вопросам обращаться в Telegram <a href="https://t.me/kupets_timur" target="_blank">@kupets_timur</a></p>
              </div>
            }
          />

          <Route path="/login" element={<Login />} />

          <Route
            path="/admin"
            element={
              <ProtectedRoute adminOnly>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <UserDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <OrderList />
              </ProtectedRoute>
            }
          />

          <Route
            path="/orders/:id/upload"
            element={
              <ProtectedRoute>
                <UploadCheque />
              </ProtectedRoute>
            }
          />

          {/* Добавьте другие маршруты по необходимости */}

        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
