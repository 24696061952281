// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Убедитесь, что импорт правильный
import { getCurrentUser } from '../api/users';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => {
    return localStorage.getItem('authToken');
  });

  const [username, setUsername] = useState(() => {
    if (authToken && typeof authToken === 'string') {
      return jwtDecode(authToken).username;
    }
  });

  const [role, setRole] = useState(() => {
    if (authToken && typeof authToken === 'string') {
      return jwtDecode(authToken).role;
    }
  });


  const loginUser = (token) => {
      localStorage.setItem('authToken', token);
      setAuthToken(token);
      setUsername(jwtDecode(token).username);
      setRole(jwtDecode(token).role);
  };

  const logoutUser = () => {
    setAuthToken(null);
    setUsername(null);
    setRole(null);
    localStorage.removeItem('authToken');
  };

  const contextData = {
    authToken,
    username,
    role,
    loginUser,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
