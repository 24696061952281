// src/components/payments/UploadCheque.js
import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { uploadCheque } from '../../api/payments';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/UploadCheque.css';

const UploadCheque = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const [cheque, setCheque] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setCheque(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cheque) {
      setError('Пожалуйста, выберите файл чека.');
      return;
    }

    const formData = new FormData();
    formData.append('pdf_cheque', cheque);

    try {
      await uploadCheque(id, formData, authToken);
      navigate('/orders');
    } catch (err) {
      setError('Ошибка при загрузке чека.', err);
    }
  };

  return (
    <div>
      <h3>Загрузить Чек для Ордера #{id}</h3>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <input type="file" accept="application/pdf" onChange={handleFileChange} required />
        <button type="submit">Загрузить</button>
      </form>
    </div>
  );
};

export default UploadCheque;
