// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Стили для загрузки чека */
.upload-cheque {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .upload-cheque h3 {
    margin-bottom: 20px;
  }
  
  .upload-cheque form {
    display: flex;
    flex-direction: column;
  }
  
  .upload-cheque input[type="file"] {
    margin-bottom: 15px;
  }
  
  .upload-cheque button {
    width: 150px;
    align-self: flex-start;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/UploadCheque.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,sBAAsB;EACxB","sourcesContent":["/* Стили для загрузки чека */\n.upload-cheque {\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .upload-cheque h3 {\n    margin-bottom: 20px;\n  }\n  \n  .upload-cheque form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .upload-cheque input[type=\"file\"] {\n    margin-bottom: 15px;\n  }\n  \n  .upload-cheque button {\n    width: 150px;\n    align-self: flex-start;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
