// src/api/users.js
import axios from 'axios';

const API_URL = 'https://centrally-topical-fox.ngrok-free.app';

export const getCurrentUser = (token) => {
  return axios.get(`${API_URL}/users/me`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getUserBalance = (token) => {
  return axios.get(`${API_URL}/users/me/balance`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Административные операции
export const getAllUsers = (token) => {
  return axios.get(`${API_URL}/admin/users`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getUserById = (id, token) => {
  return axios.get(`${API_URL}/admin/users/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateUserBalance = (id, balanceData, token) => {
  return axios.patch(`${API_URL}/admin/users/${id}/balance`, balanceData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
