// src/api/orders.js
import axios from 'axios';

const API_URL = 'https://centrally-topical-fox.ngrok-free.app';

export const getUserOrders = (token) => {
  return axios.get(`${API_URL}/orders`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getOrderById = (id, token) => {
  return axios.get(`${API_URL}/orders/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createPayment = (orderId, paymentData, token) => {
  return axios.post(`${API_URL}/orders/${orderId}/payments`, paymentData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Административные операции
export const createOrder = (orderData, token) => {
  return axios.post(`${API_URL}/admin/orders`, orderData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateOrder = (id, orderData, token) => {
  return axios.patch(`${API_URL}/admin/orders/${id}`, orderData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteOrder = (id, token) => {
  return axios.delete(`${API_URL}/admin/orders/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getAllOrders = (token) => {
  return axios.get(`${API_URL}/admin/orders`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getAdminOrderById = (id, token) => {
  return axios.get(`${API_URL}/admin/orders/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const assignOrder = (id, assignData, token) => {
  return axios.post(`${API_URL}/admin/orders/${id}/assign`, assignData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
