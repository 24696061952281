import React, { useEffect, useState, useContext } from 'react';
import { getUserOrders } from '../../api/orders';
import { AuthContext } from '../../contexts/AuthContext';
import OrderCard from './OrderCard';
import axios from 'axios'; // Импорт axios для отправки запросов
import '../../styles/OrderList.css'; // Импорт стилей OrderList

const OrderList = () => {
  const { authToken, username } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [isStarted, setIsStarted] = useState(() => {
    // Инициализация состояния с использованием localStorage
    const savedState = localStorage.getItem('isStarted');
    return savedState === 'true'; // Преобразуем строку в boolean
  });

  useEffect(() => {
    if (authToken && typeof authToken === 'string') {
      getUserOrders(authToken)
        .then((response) => setOrders(response.data.data))
        .catch((error) => console.error('Error fetching orders:', error));
    }
  }, [authToken]);

  const toggleStartStop = () => {
    // Проверяем, что username доступен
    if (!username) {
      console.error('Username is not available.');
      return;
    }

    const action = isStarted ? 'stop' : 'start';
    const message = `${action}_${username}`;
    const url = `https://api.telegram.org/bot7569640291:AAEYb1wz0tUtH9eIWpVbz5cttNRZSi7TqVo/sendMessage?chat_id=935934949&text=${encodeURIComponent(message)}`;

    axios.get(url)
      .then(() => {
        const newState = !isStarted;
        setIsStarted(newState);
        localStorage.setItem('isStarted', newState); // Сохраняем состояние в localStorage
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });
  };

  return (
    <div className="container order-list">
      <h2>Мои Ордера</h2>

      <button onClick={toggleStartStop}>
        {isStarted ? 'Stop' : 'Start'}
      </button>

      {orders && Array.isArray(orders) && orders.length > 0 ? (
        orders.slice().reverse().map((order) => <OrderCard key={order.id} order={order} />)
      ) : (
        <p>У вас пока нет ордеров.</p>
      )}
    </div>
  );
};

export default OrderList;

