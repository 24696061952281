// src/components/common/Navbar.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/Navbar.css'; // Убедитесь, что путь к CSS правильный

const Navbar = () => {
  const { role, logoutUser } = useContext(AuthContext);

  return (
    <nav className="navbar">
      <div className="container">
        <div className="left-links">
          <Link to="/">Главная</Link>
        </div>
        <div className="right-links">
          {role ? (
            <>
              <Link to="/dashboard">Панель пользователя</Link>
              <Link to="/orders">Мои ордера</Link>
              {role == 'admin' && <Link to="/admin">Админ-панель</Link>}
              <button onClick={logoutUser}>Выйти</button>
            </>
          ) : (
            <Link to="/login">Войти</Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
