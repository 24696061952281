// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Стили для списка ордеров пользователя */
.order-list {
    display: flex;
    flex-direction: column;
  }
  
  .order-list .order-card {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  }
  
  .order-list .order-card h4 {
    margin-bottom: 10px;
  }
  
  .order-list .order-card p {
    margin-bottom: 5px;
  }
  
  .order-list .order-card a {
    margin-right: 10px;
    color: #2980b9;
  }
  
  .order-list .order-card a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/OrderList.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":["/* Стили для списка ордеров пользователя */\n.order-list {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .order-list .order-card {\n    background-color: #fff;\n    padding: 15px;\n    margin-bottom: 15px;\n    border-radius: 8px;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);\n  }\n  \n  .order-list .order-card h4 {\n    margin-bottom: 10px;\n  }\n  \n  .order-list .order-card p {\n    margin-bottom: 5px;\n  }\n  \n  .order-list .order-card a {\n    margin-right: 10px;\n    color: #2980b9;\n  }\n  \n  .order-list .order-card a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
