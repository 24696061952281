// src/api/payments.js
import axios from 'axios';

const API_URL = 'https://centrally-topical-fox.ngrok-free.app';

export const uploadCheque = (orderId, chequeData, token) => {
  return axios.post(`${API_URL}/orders/${orderId}/payments`, chequeData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
