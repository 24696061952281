// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Стили для списка пользователей */
.user-list table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .user-list th, .user-list td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .user-list th {
    background-color: #f4f4f4;
  }
  
  .user-list tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-list button {
    padding: 5px 10px;
    background-color: #27ae60;
  }
  
  .user-list button:hover {
    background-color: #219150;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/UserList.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,WAAW;IACX,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Стили для списка пользователей */\n.user-list table {\n    width: 100%;\n    border-collapse: collapse;\n    background-color: #fff;\n  }\n  \n  .user-list th, .user-list td {\n    padding: 12px;\n    border: 1px solid #ddd;\n    text-align: left;\n  }\n  \n  .user-list th {\n    background-color: #f4f4f4;\n  }\n  \n  .user-list tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .user-list button {\n    padding: 5px 10px;\n    background-color: #27ae60;\n  }\n  \n  .user-list button:hover {\n    background-color: #219150;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
