// src/components/admin/AdminDashboard.js
import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import UserList from './UserList';
import OrderManagement from './OrderManagement';
import '../../styles/AdminDashboard.css';

const AdminDashboard = () => {
  return (
    <div>
      <h2>Админ-Панель</h2>
      <nav>
        <Link to="users">Управление Пользователями</Link>
        <Link to="orders">Управление Ордерами</Link>
      </nav>
      <Routes>
        <Route path="users" element={<UserList />} />
        <Route path="orders" element={<OrderManagement />} />
      </Routes>
    </div>
  );
};

export default AdminDashboard;
