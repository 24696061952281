// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Стили для формы входа */
.login-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container form div {
    margin-bottom: 15px;
  }
  
  .login-container label {
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
  }
  
  .login-container input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-container button {
    align-self: center;
    width: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Login.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb","sourcesContent":["/* Стили для формы входа */\n.login-container {\n    background-color: #fff;\n    padding: 30px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .login-container h2 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .login-container form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .login-container form div {\n    margin-bottom: 15px;\n  }\n  \n  .login-container label {\n    margin-bottom: 5px;\n    display: block;\n    font-weight: bold;\n  }\n  \n  .login-container input {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .login-container button {\n    align-self: center;\n    width: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
