// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Стили для панели пользователя */
.user-dashboard {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .user-dashboard p {
    font-size: 1.2em;
  }
  
  .user-dashboard .balance {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Дополнительные стили для информации пользователя */
  .user-dashboard .info {
    margin-bottom: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/UserDashboard.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA,qDAAqD;EACrD;IACE,mBAAmB;EACrB","sourcesContent":["/* Стили для панели пользователя */\n.user-dashboard {\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .user-dashboard p {\n    font-size: 1.2em;\n  }\n  \n  .user-dashboard .balance {\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  \n  /* Дополнительные стили для информации пользователя */\n  .user-dashboard .info {\n    margin-bottom: 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
