// src/components/admin/UserList.js
import React, { useEffect, useState, useContext } from 'react';
import { getAllUsers } from '../../api/users';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/UserList.css'; // Импорт стилей UserList

const UserList = () => {
  const { authToken } = useContext(AuthContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (authToken && typeof authToken === 'string') {
      getAllUsers(authToken)
        .then((response) => setUsers(response.data))
        .catch((error) => console.error('Ошибка при получении списка пользователей:', error));
    }
  }, [authToken]);

  return (
    <div className="user-list">
      <h3>Список Пользователей</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>Баланс</th>
            {/* Добавьте другие поля по необходимости */}
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.username}</td>
              <td>{user.balance} руб.</td>
              <td>
                {/* Пример кнопок для действий */}
                <button>Редактировать</button>
                <button>Удалить</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
