// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Стили для управления ордерами */
.order-management table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .order-management th, .order-management td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .order-management th {
    background-color: #f4f4f4;
  }
  
  .order-management tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .order-management button {
    padding: 5px 10px;
    background-color: #e67e22;
  }
  
  .order-management button:hover {
    background-color: #d35400;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/OrderManagement.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,WAAW;IACX,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Стили для управления ордерами */\n.order-management table {\n    width: 100%;\n    border-collapse: collapse;\n    background-color: #fff;\n  }\n  \n  .order-management th, .order-management td {\n    padding: 12px;\n    border: 1px solid #ddd;\n    text-align: left;\n  }\n  \n  .order-management th {\n    background-color: #f4f4f4;\n  }\n  \n  .order-management tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .order-management button {\n    padding: 5px 10px;\n    background-color: #e67e22;\n  }\n  \n  .order-management button:hover {\n    background-color: #d35400;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
