// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Стили для Админ-Панели */
.admin-dashboard {
    display: flex;
    flex-direction: column;
  }
  
  .admin-dashboard nav {
    margin-bottom: 20px;
  }
  
  .admin-dashboard nav a {
    margin-right: 15px;
  }
  
  .admin-dashboard table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .admin-dashboard th, .admin-dashboard td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .admin-dashboard th {
    background-color: #f4f4f4;
  }
  
  .admin-dashboard tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .admin-dashboard button {
    margin-right: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/AdminDashboard.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["/* Стили для Админ-Панели */\n.admin-dashboard {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .admin-dashboard nav {\n    margin-bottom: 20px;\n  }\n  \n  .admin-dashboard nav a {\n    margin-right: 15px;\n  }\n  \n  .admin-dashboard table {\n    width: 100%;\n    border-collapse: collapse;\n    background-color: #fff;\n  }\n  \n  .admin-dashboard th, .admin-dashboard td {\n    padding: 12px;\n    border: 1px solid #ddd;\n    text-align: left;\n  }\n  \n  .admin-dashboard th {\n    background-color: #f4f4f4;\n  }\n  \n  .admin-dashboard tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .admin-dashboard button {\n    margin-right: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
