// src/components/orders/OrderCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/OrderList.css';

const OrderCard = ({ order }) => {
  return (
    <div style={{ border: '1px solid gray', padding: '10px', margin: '10px 0' }}>
      <h4>Ордер #{order.id}</h4>
      <p>Сумма: {order.amount}</p>
      <p>Банк: {order.bank}</p>
      <p>Номер карты: {order.card_number}</p>
      <p>Статус: {order.status}</p>
      {order.status === 'Новая' && (
        <Link to={`/orders/${order.id}/upload`}>Загрузить чек</Link>
      )}
    </div>
  );
};

export default OrderCard;
