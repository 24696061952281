// src/components/admin/OrderManagement.js
import React, { useEffect, useState, useContext } from 'react';
import { getAllOrders, deleteOrder, updateOrder, assignOrder } from '../../api/orders';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/OrderManagement.css';

const OrderManagement = () => {
  const { authTokens } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, [authTokens]);

  const fetchOrders = () => {
    getAllOrders(authTokens.access)
      .then((response) => setOrders(response.data))
      .catch((error) => console.error(error));
  };

  const handleDelete = (id) => {
    deleteOrder(id, authTokens.access)
      .then(() => fetchOrders())
      .catch((error) => console.error(error));
  };

  const handleAssign = (id) => {
    const executorId = prompt('Введите ID исполнителя:');
    if (executorId) {
      assignOrder(id, { executorId }, authTokens.access)
        .then(() => fetchOrders())
        .catch((error) => console.error(error));
    }
  };

  return (
    <div>
      <h3>Управление Ордерами</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Описание</th>
            <th>Статус</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.description}</td>
              <td>{order.status}</td>
              <td>
                <button onClick={() => handleAssign(order.id)}>Назначить</button>
                <button onClick={() => handleDelete(order.id)}>Удалить</button>
                {/* Добавьте другие действия по необходимости */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderManagement;
