// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Сброс стилей для обеспечения консистентности между браузерами */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Основной шрифт и базовые стили */
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    line-height: 1.6;
  }
  
  /* Контейнер для централизованного размещения контента */
  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
  }
  
  /* Стилизация заголовков */
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Ссылки */
  a {
    color: #3498db;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Кнопки */
  button {
    padding: 10px 20px;
    background-color: #3498db;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  
  button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA,kEAAkE;AAClE;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA,mCAAmC;EACnC;IACE,gCAAgC;IAChC,yBAAyB;IACzB,WAAW;IACX,gBAAgB;EAClB;;EAEA,wDAAwD;EACxD;IACE,UAAU;IACV,iBAAiB;IACjB,cAAc;IACd,eAAe;EACjB;;EAEA,0BAA0B;EAC1B;IACE,mBAAmB;IACnB,WAAW;EACb;;EAEA,WAAW;EACX;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA,WAAW;EACX;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB","sourcesContent":["/* Сброс стилей для обеспечения консистентности между браузерами */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  /* Основной шрифт и базовые стили */\n  body {\n    font-family: 'Arial', sans-serif;\n    background-color: #f5f5f5;\n    color: #333;\n    line-height: 1.6;\n  }\n  \n  /* Контейнер для централизованного размещения контента */\n  .container {\n    width: 90%;\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 20px 0;\n  }\n  \n  /* Стилизация заголовков */\n  h1, h2, h3, h4, h5, h6 {\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  /* Ссылки */\n  a {\n    color: #3498db;\n    text-decoration: none;\n  }\n  \n  a:hover {\n    text-decoration: underline;\n  }\n  \n  /* Кнопки */\n  button {\n    padding: 10px 20px;\n    background-color: #3498db;\n    border: none;\n    color: #fff;\n    cursor: pointer;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n  }\n  \n  button:hover {\n    background-color: #2980b9;\n  }\n  \n  button:disabled {\n    background-color: #95a5a6;\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
