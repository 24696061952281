// src/components/user/UserDashboard.js
import React, { useContext, useEffect, useState } from 'react';
import { getUserBalance } from '../../api/users';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/UserDashboard.css'; // Импорт стилей UserDashboard

const UserDashboard = () => {
  const { authToken } = useContext(AuthContext);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (authToken && typeof authToken === 'string') {
      getUserBalance(authToken)
        .then((response) => setBalance(response.data.balance))
        .catch((error) => {
          console.error('Ошибка при получении баланса:', error);
          // Возможно, токен просрочен или недействителен, разлогиниваем пользователя
          // Если функция logoutUser доступна в контексте:
          // logoutUser();
        });
    }
  }, [authToken]);

  return (
    <div className="container user-dashboard">
      <h2>Панель Пользователя</h2>
      <p className="balance">Ваш баланс: {balance} руб.</p>
      {/* Добавьте другие сведения по необходимости */}
    </div>
  );
};

export default UserDashboard;
